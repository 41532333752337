<template>
  <section class="bg-dark">
    <div class="container pt-xl pb-sm">
      <h1 class="mb-sm">{{ title }}</h1>

      <div class="text-white">
        <slot></slot>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "static-page",

  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: white;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

ul > li {
  margin-bottom: 0.5rem;
  list-style-type: none;
}
</style>
